import React, { useEffect, useContext } from "react";
import Link from "next/link";
import { getPerformance } from "firebase/performance";
import app from "../config/firebaseApp";
import { LanguageContext } from "../context/LanguageContext";

/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
    const { language } = useContext(LanguageContext);
    useEffect(() => {
        getPerformance(app);
    }, []);
    return (
        <>
            {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
            <main
                className="h-screen bg-cover bg-top sm:bg-top"
                style={{
                    backgroundImage:
                        'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
                }}
            >
                <div className="max-w-7xl mx-auto px-4 py-60 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
                    <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                        {language.ERROR_PAGE_TITLE}
                    </h1>
                    <p className="text-sm font-semibold text-black text-opacity-50 tracking-wide">
                        {language.ERROR_PAGE_DESCRIPTION}
                    </p>

                    <div className="mt-6">
                        <Link href="/" title="Inicio" passHref>
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
                            >
                                {language.ERROR_PAGE_TEXT_BUTTON}
                            </button>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    );
}
